import chroma from "chroma-js";
import { ChromePicker } from "react-color";
import React, { useState } from "react";

export function SelectedColor({ color, onChangeColor }) {
  const [isColorPickerShown, setIsColorPickerShown] = useState(false);

  const style = {
    backgroundColor: `${color}`,
  };

  const toggleColorPicker = (e) => {
    setIsColorPickerShown(!isColorPickerShown);
  };

  var hex = chroma(color).hex();
  var contrast = chroma.contrast(color, chroma("black"));
  var textColorClass = contrast >= 4.5 ? "text-black" : "text-white";
  const onColorPicked = (pickedColor, event) => {
    console.log("color was picked");
    onChangeColor(pickedColor.hex);
  };

  return (
    <>
      <div
        className="h-20 w-20 transform transition-all ease-in-out duration-100 hover:scale-105 z-30 flex items-end relative p-2"
        style={style}
        onClick={toggleColorPicker}
      >
        <span
          className={`display-inline-block text-sm w-full text-center min-w-min ${textColorClass}`}
        >
          {hex}
        </span>
      </div>
      {isColorPickerShown && (
        <>
          <div
            className="fixed inset-0 z-20 bg-gray-800 bg-opacity-50"
            onClick={toggleColorPicker}
          ></div>
          <div className="z-30 transition-all ease-in-out duration-300">
            <ChromePicker
              color={color}
              onChange={onColorPicked}
              disableAlpha={true}
            />
          </div>
        </>
      )}
    </>
  );
}
