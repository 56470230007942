import React from "react";
import { CollapsibleMenuItem } from "./CollapsibleMenuItem";
import { TextMenuItem } from "./TextMenuItem";
import { RangeSlider } from "./RangeSlider";
import { RadioButton } from "./RadioButton";
import { SelectedColor } from "./SelectedColor";

export function Sidebar({
  colorFormat = "hex",
  isSidebarOpen,
  mainPalette,
  changeColorFormat,
  selectedColorIndex,
  updateColorTintCount,
  updateColorShadeCount,
  updateColorVariationPercent,
  updatePaletteColor,
  randomizePalette,
  generateImage,
  generateUrl,
  undo,
}) {
  return (
    <aside
      id="sidebar"
      className={`min-w-max w-80 bg-gray-800 text-white transition-all ease-in-out duration-300 ml-0 flex flex-col justify-between space overflow-y-auto ${
        !isSidebarOpen && "-ml-80"
      }`}
    >
      <ul id="main-menu" className="pt-2">
        <CollapsibleMenuItem
          label="Color Picker"
          icon="colorSwatch"
          isMenuOpen={true}
        >
          <section id="controls" className="flex flex-col flex-wrap gap-3 p-5">
            <p>Pick a color:</p>
            <SelectedColor
              color={mainPalette[selectedColorIndex].hex}
              onChangeColor={updatePaletteColor}
            />
            <div id="color-controls" className="flex-grow">
              <RangeSlider
                id="color-count"
                label="Tints"
                min="0"
                max="10"
                value={mainPalette[selectedColorIndex].lightness.tints}
                onChange={updateColorTintCount}
              />

              <RangeSlider
                id="color-count"
                label="Shades"
                min="0"
                max="10"
                value={mainPalette[selectedColorIndex].lightness.shades}
                onChange={updateColorShadeCount}
              />

              <RangeSlider
                id="variation-percent"
                label="Variation"
                min="1"
                max="50"
                step="0.1"
                value={mainPalette[selectedColorIndex].lightness.variation}
                onChange={updateColorVariationPercent}
              />
            </div>
          </section>
        </CollapsibleMenuItem>
      </ul>
      <ul id="general-menu" className="pt-2">
        <TextMenuItem icon="reply" label="Undo" onClick={undo} />
        <CollapsibleMenuItem label="Color Format" icon="adjustments">
          <section id="controls" className="flex flex-col flex-wrap gap-3 p-5">
            <RadioButton
              groupName="color-format"
              label="Hex"
              value="hex"
              onChange={changeColorFormat}
              isChecked={colorFormat === "hex"}
            />
            <RadioButton
              groupName="color-format"
              label="RGB"
              value="rgb"
              onChange={changeColorFormat}
              isChecked={colorFormat === "rgb"}
            />
            <RadioButton
              groupName="color-format"
              label="HSL"
              value="hsl"
              onChange={changeColorFormat}
              isChecked={colorFormat === "hsl"}
            />
          </section>
        </CollapsibleMenuItem>
        <TextMenuItem
          icon="refresh"
          label="Randomize Palette"
          onClick={randomizePalette}
        />
        <TextMenuItem icon="link" label="Generate URL" onClick={generateUrl} />
        <TextMenuItem
          icon="photograph"
          label="Download as Image"
          onClick={generateImage}
        />
      </ul>
    </aside>
  );
}
