import "./App.css";
import React, { useState, useEffect } from "react";
import { DerivedColor } from "./components/DerivedColor";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import "tippy.js/dist/tippy.css";
import { randomColor as randomColorLib } from "randomcolor";
import _ from "lodash";
import { ToastContainer, toast } from "react-toastify";
import { DynamicIcon } from "./components/DynamicIcon";
import { Sidebar } from "./components/Sidebar";
import html2canvas from "html2canvas";
import { PaletteStrip } from "./components/PaletteStrip";
import { DerivedPalette } from "./components/DerivedPalette";
import chroma from "chroma-js";
import { ExportingPalette } from "./components/ExportingPalette";

const saturationMode = {
  ASCENDING: 1,
  DESCENDING: 2,
  RADIAL: 3,
};

const generatePaletteFromUrlOrDefault = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const localColorCount = urlParams.get("l");
  console.log("color count", localColorCount);
  try {
    if (localColorCount > 0) {
      console.log("generating");
      var loadedPalette = [];
      for (let i = 0; i < localColorCount; i++) {
        const hex = `#${urlParams.get(`C${i}`)}`;
        const variation = parseFloat(urlParams.get(`V${i}`));
        const tints = parseInt(urlParams.get(`T${i}`));
        const shades = parseInt(urlParams.get(`S${i}`));

        const loadedColor = makeColor(hex, tints, shades, variation);

        loadedPalette.push(loadedColor);
      }
      toast("🦄 Wow so easy!");
      return loadedPalette;
    }
    return [makeColor(randomColorLib())];
  } catch (error) {
    alert("URL is invalid. Using default");
    return [makeColor(randomColorLib())];
  }
};

const makeColor = (
  hex = "#808080",
  tints = 3,
  shades = 3,
  variation = 10,
  isLocked = false
) => {
  return {
    hex: hex,
    lightness: {
      tints: tints,
      shades: shades,
      variation: variation,
    },
    saturation: {
      mode: saturationMode.ASCENDING,
      variation: variation,
    },
    isLocked: isLocked,
  };
};

const initialPalette = generatePaletteFromUrlOrDefault();
let paletteHistory = [_.cloneDeep(initialPalette)];

function App() {
  const [selectedColorIndex, setSelectedColorIndex] = useState(0);
  const [mainPalette, setMainPalette] = useState(initialPalette);
  const [exportingPalette, setExportPalette] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [colorFormat, setColorFormat] = useState("hex");

  const addColor = () => {
    const newColor = makeRandomColor();
    console.log("before", newColor);
    let palette = [...mainPalette];
    palette.push(newColor);
    console.log("palette", palette);
    setMainPaletteWithHistory(palette);
  };

  const setMainPaletteWithHistory = (palette) => {
    console.log("history", paletteHistory);
    if (paletteHistory.length === 50) {
      paletteHistory.shift();
    }
    paletteHistory.push(_.cloneDeep(mainPalette));
    setMainPalette(palette);
  };

  const changeColorFormat = (e) => {
    setColorFormat(e.target.value);
  };

  const generateUrl = (e) => {
    var url = `${window.location.origin}?l=${mainPalette.length}`;

    mainPalette.forEach((color, index) => {
      let strippedHex = `C${index}=${color.hex.replace("#", "")}`;
      let variation = `V${index}=${color.lightness.variation}`;
      let tints = `T${index}=${color.lightness.tints}`;
      let shades = `S${index}=${color.lightness.shades}`;

      let urlSegment = `&${strippedHex}&${variation}&${tints}&${shades}`;
      url += urlSegment;
    });

    console.log(url, url.length);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  Object.freeze(saturationMode);

  const removeColor = (index) => {
    console.log("removing");
    const newPalette = mainPalette.filter((color, i) => index !== i);
    setMainPaletteWithHistory(newPalette);

    if (selectedColorIndex === index && index - 1 >= 0) {
      setSelectedColorIndex(index - 1);
    } else if (selectedColorIndex > index) {
      setSelectedColorIndex(selectedColorIndex - 1);
    } else if (selectedColorIndex < index) {
    } else {
      setSelectedColorIndex(index);
    }
  };

  const toggleLock = (index) => {
    let newPalette = _.cloneDeep(mainPalette);
    newPalette[index].isLocked = !newPalette[index].isLocked;
    setMainPalette(newPalette);
  };

  const updatePaletteColor = (hex) => {
    let newPalette = _.cloneDeep(mainPalette);
    newPalette[selectedColorIndex].hex = hex;
    setMainPaletteWithHistory(newPalette);
  };

  const updateColorTintCount = (e) => {
    let newPalette = _.cloneDeep(mainPalette);
    newPalette[selectedColorIndex].lightness.tints = e.target.value;
    setMainPaletteWithHistory(newPalette);
  };

  const updateColorShadeCount = (e) => {
    let newPalette = _.cloneDeep(mainPalette);
    newPalette[selectedColorIndex].lightness.shades = e.target.value;
    setMainPaletteWithHistory(newPalette);
  };

  const updateColorVariationPercent = (e) => {
    let newPalette = _.cloneDeep(mainPalette);
    newPalette[selectedColorIndex].lightness.variation = e.target.value;
    setMainPaletteWithHistory(newPalette);
  };

  const randomizePalette = (e) => {
    const newPalette = mainPalette.map((color) => {
      if (!color.isLocked) {
        return makeRandomColor();
      }
      return color;
    });

    setMainPaletteWithHistory(newPalette);
  };

  const undo = (e) => {
    if (paletteHistory.length === 1) return;
    const previousPalette = paletteHistory.pop();
    setMainPalette(previousPalette);
  };

  const generateImage = (e) => {
    html2canvas(document.querySelector("#exporting-palette"), {
      removeContainer: true,
    }).then((canvas) => {
      console.log(canvas);
      var imagedata = canvas.toDataURL("image/png");
      var link = document.createElement("a");
      link.download = `palett-o-matic_export-${Math.floor(
        Date.now() / 1000
      )}.png`;
      link.href = imagedata;
      link.click();
    });
  };

  const makeRandomColor = (e) => {
    return makeColor(randomColorLib());
  };

  const moveColorDown = (index) => {
    console.log("moving down", index);
    const newPalette = [...mainPalette];
    const temp = newPalette[index];
    newPalette[index] = newPalette[index + 1];
    newPalette[index + 1] = temp;
    console.log("reordered palette", newPalette);
    setMainPaletteWithHistory(newPalette);

    if (selectedColorIndex === index) {
      setSelectedColorIndex(index + 1);
    } else if (selectedColorIndex === index + 1) {
      setSelectedColorIndex(index);
    }
  };

  const moveColorUp = (index) => {
    const newPalette = [...mainPalette];
    const temp = newPalette[index];
    newPalette[index] = newPalette[index - 1];
    newPalette[index - 1] = temp;

    setMainPaletteWithHistory(newPalette);

    if (selectedColorIndex === index) {
      setSelectedColorIndex(index - 1);
    } else if (selectedColorIndex === index - 1) {
      setSelectedColorIndex(index);
    }
  };

  const generateTintsShades = (color) => {
    let [h, s, l] = chroma(color.hex).hsl();

    let tintsShades = [];

    let changedLightness = l;
    for (let i = 0; i < color.lightness.tints; i++) {
      changedLightness += color.lightness.variation / 100;
      let tint = chroma(h, s, changedLightness, "hsl").hex();
      tintsShades.unshift(
        <DerivedColor key={`t${i}`} color={tint} format={colorFormat} />
      );
    }

    tintsShades.push(
      <DerivedColor key="o" color={color.hex} format={colorFormat} />
    );

    changedLightness = l;
    for (let i = 0; i < color.lightness.shades; i++) {
      changedLightness -= color.lightness.variation / 100;
      let shade = chroma(h, s, changedLightness, "hsl").hex();
      tintsShades.push(
        <DerivedColor key={`s${i}`} color={shade} format={colorFormat} />
      );
    }

    return tintsShades;
  };

  return (
    <>
      <div
        id="appRoot"
        className="app flex flex-col h-screen font-body max-h-screen"
      >
        <Header toggleSidebar={toggleSidebar} />
        <div className="flex flex-row flex-grow content-stretch relative overflow-hidden">
          <button
            className="absolute right-3 bottom-3 z-1000 rounded-full w-16 h-16 text-white bg-gray-800 flex justify-center items-center hover:scale-105 z-50"
            onClick={addColor}
          >
            <DynamicIcon name="plus" classes="h-8 w-8" />
          </button>
          <Sidebar
            isSidebarOpen={isSidebarOpen}
            mainPalette={mainPalette}
            colorFormat={colorFormat}
            changeColorFormat={changeColorFormat}
            selectedColorIndex={selectedColorIndex}
            updateColorTintCount={updateColorTintCount}
            updateColorShadeCount={updateColorShadeCount}
            updateColorVariationPercent={updateColorVariationPercent}
            updatePaletteColor={updatePaletteColor}
            randomizePalette={randomizePalette}
            generateImage={generateImage}
            generateUrl={generateUrl}
            undo={undo}
          />
          <main className="flex flex-col w-full bg-gray-100">
            <PaletteStrip mainPalette={mainPalette} />
            <DerivedPalette
              mainPalette={mainPalette}
              selectedColorIndex={selectedColorIndex}
              setSelectedColorIndex={setSelectedColorIndex}
              removeColor={removeColor}
              moveColorDown={moveColorDown}
              moveColorUp={moveColorUp}
              colorFormat={colorFormat}
              toggleLock={toggleLock}
              generateTintsShades={generateTintsShades}
            />
          </main>
        </div>
        <Footer />
      </div>
      <ExportingPalette
        mainPalette={mainPalette}
        colorFormat={colorFormat}
        generateTintsShades={generateTintsShades}
      />
    </>
  );
}

export default App;
