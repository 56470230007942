import React from "react";
import {
  ColorSwatchIcon,
  PhotographIcon,
  LinkIcon,
  AdjustmentsIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  TrashIcon,
  PencilIcon,
  CheckIcon,
  RefreshIcon,
  LockOpenIcon,
  LockClosedIcon,
  ReplyIcon,
  PlusIcon,
} from "@heroicons/react/outline";

export function DynamicIcon({ name, classes = "h-5 w-5" }) {
  const components = {
    adjustments: AdjustmentsIcon,
    colorSwatch: ColorSwatchIcon,
    photograph: PhotographIcon,
    link: LinkIcon,
    chevronUp: ChevronUpIcon,
    chevronDown: ChevronDownIcon,
    trash: TrashIcon,
    pencil: PencilIcon,
    check: CheckIcon,
    refresh: RefreshIcon,
    lockClosed: LockClosedIcon,
    lockOpen: LockOpenIcon,
    reply: ReplyIcon,
    plus: PlusIcon,
  };

  const Icon = components[name];
  return <Icon className={classes} />;
}
