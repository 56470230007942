import { CopyToClipboard } from "react-copy-to-clipboard";
import React from "react";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";

export function CopyButton({ textToCopy }) {
  const showMessage = (e) => {
    const elem = e.target;
    let tooltip = tippy(elem, {
      duration: [500, 500],
      arrow: true,
      delay: 0,
      content: "Copied!",
      theme: "light",
      onHidden: (instance) => instance.destroy(),
    });

    tooltip.show();
  };

  return (
    <CopyToClipboard text={textToCopy}>
      <div
        onClick={showMessage}
        className="opacity-0 hover:opacity-100 transition-all duration-200 ease-in-out block w-full h-full absolute left-0 top-0 flex justify-center items-center"
      >
        <p className="bg-white w-full px-2 shadow text-center pointer-events-none">
          Copy
        </p>
      </div>
    </CopyToClipboard>
  );
}
