import { DynamicIcon } from "./DynamicIcon";

export function TextMenuItem({ icon, label, onClick }) {
  return (
    <li
      className="flex w-full block h-10 items-center p-3 cursor-pointer hover:text-yellow-300"
      onClick={() => onClick()}
    >
      {icon && <DynamicIcon name={icon} />}
      <span className={`ml-1 flex-grow ${!icon && "ml-6"}`}>{label}</span>
    </li>
  );
}
