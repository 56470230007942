import chroma from "chroma-js";
import { DynamicIcon } from "./DynamicIcon";

export function StatusDisplayBlock({ color, iconName }) {
  var contrast = chroma.contrast(color, chroma("black"));
  var textColorClass = contrast >= 5 ? "text-black" : "text-white";

  return (
    <div
      className={`statDisplay pointer-events-none absolute w-full h-full flex justify-end pt-1 pr-2 z-50 ${textColorClass}`}
    >
      <DynamicIcon name="lockClosed" />
    </div>
  );
}
