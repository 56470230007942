export function HeaderLogo() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 200 45">
      <path
        id="curve"
        fill="none"
        stroke="none"
        d="m 20 43 a 200 200 0 0 1 160 0"
      ></path>
      <text width="200" fontSize="2.15rem" fill="#fff">
        <textPath xlinkHref="#curve">Palett-O-Matic</textPath>
      </text>
    </svg>
  );
}
