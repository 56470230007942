export function RangeSlider({
  id,
  label,
  min,
  max,
  value,
  onChange,
  step = 1,
}) {
  return (
    <div className="">
      <label htmlFor={id} className="w-full display-block">
        {label}
      </label>

      <div className="flex justify-center w-full items-center">
        <input
          id={id}
          type="range"
          className="appearance-none outline-none w-full h-3 bg-gray-300 rounded-full mr-2 display-inline-block"
          min={min}
          max={max}
          step={step}
          value={value}
          onInput={onChange}
        />
        <input
          type="number"
          min={min}
          max={max}
          value={value}
          step={step}
          onChange={onChange}
          className="rounded border w-16 p-2 bg-transparent"
        />
      </div>
    </div>
  );
}
