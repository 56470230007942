import chroma from "chroma-js";

export function ColorDisplayBlock({ color, format }) {
  var contrast = chroma.contrast(color, chroma("black"));
  var textColorClass = contrast >= 5 ? "text-black" : "text-white";

  switch (format) {
    case "rgb":
      const rgb = chroma(color).rgb(false);
      return (
        <ul
          className={`pointer-events-none display-inline-block text-sm ${textColorClass}`}
        >
          <li>
            <strong>R</strong>: {rgb[0]}
          </li>
          <li>
            <strong>G</strong>: {rgb[1]}
          </li>
          <li>
            <strong>B</strong>: {rgb[2]}
          </li>
        </ul>
      );
    case "hsl":
      let hsl = chroma(color).hsl();
      if (isNaN(hsl[0])) {
        hsl[0] = 0;
      }
      hsl = hsl.map((val) => Number(Math.round(val + "e2") + "e-2"));
      return (
        <ul
          className={`pointer-events-none display-inline-block text-sm ${textColorClass}`}
        >
          <li>
            <strong>H</strong>: {hsl[0]}
          </li>
          <li>
            <strong>S</strong>: {hsl[1]}
          </li>
          <li>
            <strong>L</strong>: {hsl[2]}
          </li>
        </ul>
      );
    default:
      const hex = chroma(color).hex();
      return (
        <p
          className={`pointer-events-none display-inline-block text-sm ${textColorClass}`}
        >
          {hex}
        </p>
      );
  }
}
