import React, { useState } from "react";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { DynamicIcon } from "./DynamicIcon";

export function CollapsibleMenuItem({
  icon,
  label,
  children,
  isMenuOpen = false,
}) {
  const [isOpen, setIsOpen] = useState(isMenuOpen);

  return (
    <li className="w-full">
      <div
        className="flex w-full block h-10 items-center p-3 cursor-pointer hover:text-yellow-300"
        onClick={() => setIsOpen(!isOpen)}
      >
        {icon && <DynamicIcon name={icon} />}
        <span className={`ml-1 flex-grow inline-block ${!icon && "ml-6"}`}>
          {label}
        </span>
        {isOpen ? (
          <ChevronRightIcon
            className={`h-4 w-4 transition-all ease-in-out duration-300 transform ${
              isOpen && "rotate-90"
            }`}
          />
        ) : (
          <ChevronRightIcon className="h-4 w-4" />
        )}
      </div>
      <ul
        className={`bg-gray-900 overflow-hidden transition-all duration-300 ease-in-out shadow shadow-inner ${
          isOpen ? "max-h-screen" : "max-h-0"
        }`}
      >
        {children}
      </ul>
    </li>
  );
}
