import { MiniColor } from "./MiniColor";

export function PaletteStrip({ mainPalette }) {
  return (
    <section id="main-palette" className="flex">
      {mainPalette.map((color, index) => {
        return <MiniColor key={index} color={color.hex} />;
      })}
    </section>
  );
}
