import { DerivedColor } from "./DerivedColor";
import { ChevronRightIcon } from "@heroicons/react/solid";

export function ExportingPalette({
  mainPalette,
  generateTintsShades,
  colorFormat,
}) {
  const exportingPalette = mainPalette.map((color, index) => {
    let row = generateTintsShades(color);

    return (
      <div className={`flex mb-5 gap-3`}>
        <DerivedColor color={color.hex} format={colorFormat} />
        <div className="h-28 flex items-center">
          <ChevronRightIcon className="h-5 w-5" />
        </div>
        <div className="flex">{row}</div>
      </div>
    );
  });

  return (
    <div id="exporting-palette" className="w-max p-5 fixed">
      {exportingPalette}
    </div>
  );
}
