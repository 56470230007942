import { HeaderLogo } from "./HeaderLogo";
import { MenuIcon } from "@heroicons/react/solid";

export function Header({ toggleSidebar }) {
  return (
    <header className="bg-gray-900 text-white p-4 font-display flex justify-start h-20 w-full">
      <MenuIcon
        className="h-10 hw-10 float-left cursor-pointer"
        onClick={() => toggleSidebar()}
      />
      <HeaderLogo />
    </header>
  );
}
