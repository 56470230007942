import { PaletteColor } from "./PaletteColor";
import chroma from "chroma-js";
import { ChevronRightIcon } from "@heroicons/react/solid";

export function DerivedPalette({
  mainPalette,
  selectedColorIndex,
  setSelectedColorIndex,
  removeColor,
  moveColorDown,
  moveColorUp,
  colorFormat,
  toggleLock,
  generateTintsShades,
}) {
  const derivedPalette = mainPalette.map((color, index) => {
    let row = generateTintsShades(color);

    const isFirst = index === 0;
    const isLast = index === mainPalette.length - 1;
    return (
      <div className={`flex mb-5 gap-3`}>
        <PaletteColor
          key={index}
          isFirst={isFirst}
          isLast={isLast}
          color={color}
          onClick={() => setSelectedColorIndex(index)}
          format={colorFormat}
          isSelected={index === selectedColorIndex}
          onRemove={() => {
            removeColor(index);
          }}
          onMoveUp={() => moveColorUp(index)}
          onMoveDown={() => moveColorDown(index)}
          onEdit={() => setSelectedColorIndex(index)}
          onToggleLock={() => toggleLock(index)}
        />

        <div className="h-28 flex items-center">
          <ChevronRightIcon className="h-5 w-5" />
        </div>
        <div className="flex flex-wrap">{row}</div>
      </div>
    );
  });

  return (
    <section
      id="full-palette"
      className="flex-grow flex flex-col mx-auto w-full overflow-y-auto relative"
    >
      <div className="p-10">{derivedPalette}</div>
    </section>
  );
}
