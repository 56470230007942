import chroma from "chroma-js";
import React, { forwardRef } from "react";
import { Color } from "./Color";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  LockOpenIcon,
  LockClosedIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/solid";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import { DynamicIcon } from "./DynamicIcon";
import { StatusDisplayBlock } from "./StatusDisplayBlock";

const TippyIcon = forwardRef(({ name, onClick }, ref) => {
  return (
    <span ref={ref} onClick={onClick}>
      <DynamicIcon
        name={name}
        classes="h-5 w-5 opacity-20 hover:opacity-100 hover:scale-105"
      />
    </span>
  );
});

export function PaletteColor({
  color,
  format,
  onClick,
  isSelected = false,
  onRemove,
  onMoveUp,
  onMoveDown,
  onEdit,
  isLast,
  isFirst,
  onToggleLock,
}) {
  return (
    <div>
      <div
        className={`relative ${
          isSelected && "ring ring-gray-900 ring-offset-2"
        }`}
      >
        {color.isLocked && <StatusDisplayBlock color={color.hex} />}
        <Color
          color={color.hex}
          format={format}
          onClick={color.isLocked ? null : onClick}
        />
      </div>
      <div className="flex justify-between mt-2">
        {isFirst ? (
          <div className="h-5 w-5"></div>
        ) : (
          <Tippy content="Move up" placement="bottom">
            <TippyIcon name="chevronUp" onClick={onMoveUp} />
          </Tippy>
        )}
        {isLast ? (
          <div className="h-5 w-5"></div>
        ) : (
          <Tippy content="Move down" placement="bottom">
            <TippyIcon name="chevronDown" onClick={onMoveDown} />
          </Tippy>
        )}

        {color.isLocked ? (
          <div className="h-5 w-5"></div>
        ) : (
          <Tippy content="Edit color" placement="bottom">
            <TippyIcon name="pencil" onClick={onEdit} />
          </Tippy>
        )}

        <Tippy content={color.isLocked ? "Unlock" : "Lock"} placement="bottom">
          <TippyIcon
            name={color.isLocked ? "lockOpen" : "lockClosed"}
            onClick={onToggleLock}
          />
        </Tippy>

        {isFirst && isLast ? (
          <div className="h-5 w-5"></div>
        ) : (
          <Tippy content="Remove" placement="bottom">
            <TippyIcon name="trash" onClick={onRemove} />
          </Tippy>
        )}
      </div>
    </div>
  );
}
