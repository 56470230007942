export function RadioButton({ groupName, label, value, onChange, isChecked }) {
  const id = `${groupName}-${value}`;
  return (
    <span className="inline-block">
      <input
        type="radio"
        name={groupName}
        id={id}
        value={value}
        className="mr-1"
        onChange={onChange}
        checked ={isChecked}
      />
      <label htmlFor={id}>{label}</label>
    </span>
  );
}
