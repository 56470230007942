import chroma from "chroma-js";
import React from "react";
import { CopyButton } from "./CopyButton";
import { ColorDisplayBlock } from "./ColorDisplayBlock";

export function Color({
  color,
  onClick = null,
  copyable = false,
  format,
  pickable = false,
  onChange = null,
}) {
  const style = {
    backgroundColor: `${color}`,
  };

  var label =
    format === "rgb"
      ? chroma(color).css()
      : format === "hsl"
      ? chroma(color).css("hsl")
      : chroma(color).hex();

  return (
    <div
      className="h-28 w-28 transform hover:scale-105 hover:z-10 p-2 flex justify-center items-center relative transition-all ease-in-out duration-100"
      style={style}
      onClick={onClick}
    >
      {copyable && <CopyButton textToCopy={label} />}

      <ColorDisplayBlock color={color} format={format} />
    </div>
  );
}
